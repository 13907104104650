import { http, authHeader } from '@/http-commons';

class VentaDetalleLoteDataService {

    tableByEntradaDetalle(params, entradaDetalleId){
        return http.get(`/api/v1/ventaDetalleLote/tableByEntradaDetalle/${entradaDetalleId}`, { params, headers: authHeader()});
    }
    getVentaDetalleLoteByVenta(ventaId){
        return http.get(`/api/v1/ventaDetalleLote/getVentaDetalleLoteByVenta/${ventaId}`, { headers: authHeader()});
    }
    downloadVentaDetalleLoteExcelByTipoSalida( params ){
        return http.get(`api/v1/ventaDetalleLote/downloadVentaDetalleLoteExcelByTipoSalida`, { params, headers: authHeader(), responseType: 'blob' });
    }
}

export default new VentaDetalleLoteDataService();