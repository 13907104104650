<template>
    <v-row>
        <v-col cols="12" md="12" lg="12" xl="12">
            <v-btn color="primary" @click.prevent="addProduct()" :disabled="tipoEntrada == 2">
                <v-icon left>fas fa-plus-square</v-icon> Añadir producto ( F2 )
            </v-btn>
            <v-simple-table fixed-header class="elevation-1" dense>
                <template v-slot:default>
                    <thead class="primary">
                        <tr>
                            <th class="text-left">Producto</th>
                            <th class="text-left">Cantidad recibida</th>
                            <th class="text-left">Precio</th>
                            <th class="text-left">Peso promedio</th>
                            <th class="text-left">Peso neto</th>
                            <th class="text-left">Importe</th>
                            <th class="text-left">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in entradaProduct" :key="item.id">
                            <td>
                                <ValidationProvider rules="required" v-slot="{ errors, valid}" :immediate="true">
                                    <v-autocomplete class="mt-5 mr-5" :items="products" item-text="nombre" item-value="id"
                                    dense filled required solo-inverted
                                    @change="(e) => changeProduct(e, index)"
                                    :value="item.productoId" :error-messages="errors" :success="valid">
                                    </v-autocomplete>
                                </ValidationProvider>
                            </td>
                            <td>
                                <ValidationProvider rules="required|numeric|min_value:1" v-slot="{ errors, valid }" :immediate="true">
                                    <v-text-field v-model="item.cantidad" type="number" label="Cantidad" class="mt-3"
                                        outlined persistent-hint prepend-inner-icon="fas fa-boxes" required min="1"
                                        @input="(e) => changeItem(e, index, 'cantidad')"
                                        :error-messages="errors" :success="valid">
                                    </v-text-field>
                                </ValidationProvider>
                            </td>
                            <td>
                                <ValidationProvider :rules=" tipoEntrada != 2 ? 'required|numeric|min_value:1' : 'numeric'" v-slot="{ errors, valid }" :immediate="true">
                                    <v-text-field v-model="item.precioUnitario" type="number" label="Precio" class="mt-3"
                                        outlined persistent-hint prepend-inner-icon="fas fa-dollar-sign"
                                        @input="(e) => changeItem(e, index, 'precioUnitario')"
                                        :disabled="tipoEntrada == 2"
                                        required min="1" :error-messages="errors" :success="valid">
                                    </v-text-field>
                                </ValidationProvider>
                            </td>
                            <td>
                                <ValidationProvider rules="required|decimal|min_value:1" v-slot="{ errors, valid }" :immediate="true">
                                    <v-text-field v-model="item.averageWeight" type="number" label="Peso promedio" class="mt-3"
                                        outlined persistent-hint prepend-inner-icon="fas fa-weight" required min="1"
                                        @input="(e) => changeItem(e, index, 'averageWeight')"
                                        :error-messages="errors" :success="valid">
                                    </v-text-field>
                                </ValidationProvider>
                            </td>
                            <td>
                                <ValidationProvider rules="required|decimal|min_value:1" v-slot="{ errors, valid }" :immediate="true">
                                    <v-text-field :value="item.netWeight" type="number" label="Peso neto" class="mt-3"
                                        outlined persistent-hint prepend-inner-icon="fas fa-weight-hanging" required min="1"
                                        @input="(e) => changeItem(e, index, 'netWeight')"
                                        :error-messages="errors" :success="valid"
                                        >
                                    </v-text-field>
                                </ValidationProvider>
                            </td>
                            <td>
                                <v-text-field :value="item.importe" type="text" label="Importe" class="mt-3"
                                    outlined persistent-hint prepend-inner-icon="fas fa-dollar-sign" disabled>
                                </v-text-field>
                            </td>
                            <td>
                                <v-btn icon @click="deleteProduct(item, index)">
                                    <v-icon color="red darken-1">fas fa-trash-alt</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-col>
    </v-row>
</template>
<script>
import { getCurrency } from '@/utils/CurrencyUtil';
export default {
    name: "addProductEntry",
    props: {
        products: { type: Array, default: () => [] },
        tipoEntrada: { type: Number, default: 1 },
        entrada: { type: Object, default: () => {} },
        mode: { type: String, default: 'add' }
    },
    mounted() {
        document.addEventListener('keydown', this.addProductEntryKey);
    },
    destroyed() {
        document.removeEventListener('keydown', this.addProductEntryKey);
    },
    created() { this.addProduct() },
    data() {
        return {
            entradaProduct: [],
        }
    },
    methods: {
        generateNewEntry() {
            return {
                id: null,
                entradaId: null,
                productoId: null,
                cantidad: 0,
                averageWeight: 0,
                netWeight: 0,
                precioUnitario: 0,
                importe: 0,
                prioridad: 1,
                merma: 0,
                existencia: 0,
                totalSalida: 0,
                totalSalidaTraspaso: 0,
                totalEntradaTraspaso: 0,
                activo: true,
                eliminado: false
            }
        },
        addProductEntryKey(e) {
            if (e.keyCode === 174) {
                this.addProduct()
            }
        },
        addProduct() {
            if(this.entradaProduct.length > 0) {
                const hasProductEmpty = this.entradaProduct.find(x => x.productoId == null);
                if(hasProductEmpty){
                    this.$swal.fire({ position: 'top-end', icon: 'info', title: 'Ya existe una lista vacia', text: 'Debe seleccionar un producto', showConfirmButton: false, timer: 2500 });
                    return
                }
            }
            this.entradaProduct.push(this.generateNewEntry())
        },
        deleteProduct(entradaProduct, index) {
            this.enableProduct(entradaProduct.productoId, entradaProduct);
            this.entradaProduct.splice(index, 1);
        },
        enableProduct(productSelected, entradaProduct) {
            const indexProduct = this.products.findIndex(x => x.id == productSelected);
            const indexPrevProduct = this.products.findIndex(x => x.id == entradaProduct.productoId);
            if(indexProduct > -1){
                this.products[indexProduct].disabled = true;
            }
            if(indexPrevProduct > -1) {
                this.products[indexPrevProduct].disabled = false;
            }
        },
        changeProduct(e, index) {
            if(e != null && e > 0){
                this.enableProduct(e, this.entradaProduct[index]);
                this.entradaProduct[index].productoId = e;
                let product = this.products.find(producto => producto.id == e);
             
                this.entradaProduct[index].cantidad = 1;
                this.entradaProduct[index].precioUnitario = this.tipoEntrada == 2 ? 0 : product.precioVenta;
                this.entradaProduct[index].importe = this.tipoEntrada == 2 ? 0 : (product.precioVenta * 1);
            }
        },
        changeItem(e, index, field) {
            if(e != null && e > 0){
                this.entradaProduct[index][field] = e;
                // const netWeight = this.entradaProduct[index].averageWeight * this.entradaProduct[index].cantidad;
                // this.entradaProduct[index].netWeight = getCurrency(netWeight);
                this.entradaProduct[index].importe = this.entradaProduct[index].cantidad * this.entradaProduct[index].precioUnitario;
            }
        },
        emitDataEntradaProduct( data ){
            this.$emit('addEntradaProduct', data);
        },
        reEnableAllProducts() {
            this.products.forEach(product => product.disabled = false);
        }
    },
    watch: {
        entradaProduct: {
            handler(newVal) {
                this.emitDataEntradaProduct(newVal);
            },
            deep: true
        },
        tipoEntrada: {
            handler(newVal) {
                if(newVal == 1) {
                    this.reEnableAllProducts();
                }
            }
        },
        'entrada.sucursalRecibeId': function(newValue){
            if( newValue != null ){
                this.reEnableAllProducts();
            }
        }
    }
}
</script>