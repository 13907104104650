import { http, authHeader } from '@/http-commons';

class TipoEntradaService{

    create( tipoEntrada ){
        return http.post('api/v1/catalogo/tipoEntrada/create', tipoEntrada, { headers : authHeader() });
    }

    show( id ){
        return http.get(`api/v1/catalogo/tipoEntrada/show/${id}`, { headers : authHeader() });
    }

    update( id, tipoEntrada ){
        return http.put(`api/v1/catalogo/tipoEntrada/update/${id}`, tipoEntrada, { headers : authHeader() });
    }

    delete ( id ){
        return http.delete(`api/v1/catalogo/tipoEntrada/delete/${id}`, {headers : authHeader() });
    }

    getAllTipoEntrada(){
        return http.get('api/v1/catalogo/tipoEntrada/all', { headers : authHeader() });
    }

    getTipoEntradaTable( params ){
        return http.get('api/v1/catalogo/tipoEntrada/table', { params, headers : authHeader() });
    }

}
export default new TipoEntradaService();