import { http, authHeader } from '@/http-commons';

class ProveedorDataService {

    create( supplier ){
        return http.post('api/v1/proveedor/create', supplier , { headers : authHeader()} );
    }
    getAll( params ){
        return http.get('api/v1/proveedor/table',{  params, headers : authHeader()} );
    }
    edit( id ){
        return http.get(`api/v1/proveedor/show/${id}`, { headers : authHeader()} );
    }
    jsonProveedor( id ){
        return http.get(`api/v1/proveedor/show/${id}`, { headers : authHeader() });
    }
    update( supplier ){
        return http.put(`api/v1/proveedor/update/${supplier.idProveedor}`, supplier , { headers : authHeader()} );
    }
    delete ( id ){
        return http.delete(`api/v1/proveedor/delete/${id}`, { headers : authHeader() });
    }
    restore ( id ){
        return http.get(`api/v1/proveedor/restore/${id}`, { headers : authHeader() });
    }
    allProveedor(){
        return http.get(`api/v1/proveedor/allProveedor`, { headers: authHeader() });
    }
}
export default new ProveedorDataService();