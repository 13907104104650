<template>
    <v-menu bottom offset-x rounded="lg" transition="slide-y-transition" color="primary">
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" color="teal darken-1" class="white--text ma-1">
                <v-icon>fas fa-ellipsis-v</v-icon>
            </v-btn>
        </template>
        <v-list>
            <v-list-item link :to="{ name: 'ListaEntradaDetalleLote', params: { entradaDetalleId: item.id }}">
                <v-list-item-title>
                    <v-icon small class="mr-2">fas fa-balance-scale</v-icon> Detalle
                    entrada
                </v-list-item-title>
            </v-list-item>
            <v-list-item link @click="downloadExcelVentaDetalle(item.id, 1)">
                <v-list-item-title>
                    <v-icon small class="mr-2">fas fa-chart-line</v-icon> Reporte de ventas
                </v-list-item-title>
            </v-list-item>
            <v-list-item link @click="downloadExcelVentaDetalle(item.id, 2)">
                <v-list-item-title>
                    <v-icon small class="mr-2">fas fa-exclamation-triangle</v-icon> Reporte de mermas
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>
<script>
import VentaDetalleLoteDataService from '@/service/venta/VentaDetalleLoteDataService';

export default {
    name: 'ActionsEntrada',
    props: {
        item:{ type: Object, required: true }
    },
    methods: {
        downloadExcelVentaDetalle(entradaDetalleId, tipoSalidaId){
            this.$swal.fire({
                title: 'Quieres continuar con la descarga del reporte de detalle de la entrada?',
                text: 'El tiempo de descarga puede variar dependiendo de la cantidad de registros',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    const params = { entradaDetalleId, tipoSalidaId }
                    try {
                        return await VentaDetalleLoteDataService.downloadVentaDetalleLoteExcelByTipoSalida(params);
                    } catch (error) {
                        this.$swal.showValidationMessage(`Petición fallida: ${error}`);
                    }
                },
                allowOutsideClick: () => !this.$swal.isLoading()
            }).then((result) => {
                if (result.isConfirmed) {
                    if(result.value.status == 204){
                        this.$swal.fire({ icon: 'info', title: 'Reporte', text: 'No hay registros para descargar', showConfirmButton: false, timer: 1500 });
                    } else {
                        const url = window.URL.createObjectURL(new Blob([result.value.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `Salidas_de_la_entrada_${this.item.ticketFolio}.xlsx`);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                        this.$swal.fire({ icon: 'success', title: 'Reporte', text: 'Descarga completada', showConfirmButton: false, timer: 1500 });
                    }
                }
            })
        }
    }
}
</script>