import { http, authHeader } from '@/http-commons';

class ProductoDataService {
    store( producto){
        return http.post('api/v1/producto/store', producto, { headers: authHeader() });
    }
    uploadPhoto(photo, productoId){
        let formData = new FormData();
        formData.append("photo", photo);
        return http.post(`api/v1/producto/uploadPhoto/${productoId}`, formData, { headers: authHeader() });
    }
    findAll(typeService = false ){
        return http.get('api/v1/producto/findAllProducto', { params: {typeService}, headers : authHeader()} );
    }
    show( id ){
        return http.get(`api/v1/producto/show/${id}`, { headers : authHeader()} );
    }
    findByClave( clave ){
        return http.get(`api/v1/producto/showClave/${clave}`, { headers : authHeader() });
    }
    update( producto){
        return http.post(`api/v1/producto/update/${producto.id}`, producto , { headers : authHeader()} );
    }
    updatePhoto(photo, productoId){
        let formData = new FormData();
        formData.append("photo", photo);
        return http.post(`api/v1/producto/updatePhoto/${productoId}`, formData, { headers: authHeader() });
    }

    delete ( id ){
        return http.delete(`api/v1/producto/delete/${id}`, { headers : authHeader() });
    }
    restore (id){
        return http.get(`api/v1/producto/restore/${id}`, { headers : authHeader() });
    }

    listTable( params ){
        return http.get('api/v1/producto/table', { params, headers : authHeader() });
    }
}
export default new ProductoDataService();