import { http, authHeader } from '@/http-commons';

class TipoMonedaDataService {

    getAllTipoMoneda(){
        return http.get('api/v1/tipoMoneda/allTipoMoneda', { headers : authHeader() });
    }
    findByID( id ){
        return http.get(`api/v1/tipoMoneda/show/${id}`, {headers : authHeader() });
    }

}
export default new TipoMonedaDataService();