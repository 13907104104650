import { http, authHeader } from '@/http-commons';

class TipoPagoDataService {
    allTipoPago(){
        return http.get('api/v1/tipoPago/allTipoPago', { headers : authHeader() });
    }
    findByID( idTipoPago ){
        return http.get(`api/v1/tipoPago/show/${idTipoPago}`, {headers : authHeader() });
    }

}
export default new TipoPagoDataService();