<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card class="elevation-1">
            <v-toolbar dark color="primary">
                <v-btn icon dark @click.native="handleModal">
                <v-icon>fas fa-times-circle</v-icon>
                </v-btn>
                <v-toolbar-title>Generar reporte de entrada en excel</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <SingleCalendar refElement="startDate" title="Fecha inicio" :input-date.sync="startDate" />
                    </v-col>
                    <v-col cols="6">
                        <SingleCalendar refElement="endDate" title="Fecha fin" :input-date.sync="endDate" :min-date="startDate" />
                    </v-col>
                    <v-col cols="12">
                        <v-autocomplete :items="typeEntry" item-text="nombre" item-value="id"
                        prepend-inner-icon="fas fa-outdent" hint="Selecciona el tipo de entrada."
                        v-model="typeEntryId" label="Tipo entrada" required outlined >
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                        <v-autocomplete :items="brands" item-text="nombre" item-value="id" v-model="brandId"
                        label="Sucursales" required outlined prepend-inner-icon="fas fa-id-card-alt"
                        :readonly="!isAdmin" hint="Seleccione una sucursal. Este campo es opcional">
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" class="mt-n12">
                        <v-checkbox v-model="showDetailByProduct" label="Reporte detallado por producto" color="success" hide-details></v-checkbox>
                    </v-col>
                    <v-col cols="12" v-if="showDetailByProduct" class="mt-n5">
                        <ValidationProvider rules="required" v-slot="{ errors, valid}" :immediate="true">
                            <v-autocomplete :items="products" item-text="nombre" item-value="id" v-model="productId"
                            prepend-inner-icon="fas fa-boxes" hint="Seleccione un producto. Este campo es obligatorio si el reporte es detallado."
                            label="Producto" required outline :error-messages="errors" :success="valid">
                            </v-autocomplete>
                        </ValidationProvider>
                    </v-col>
                    <v-col cols="12">
                        <v-autocomplete :items="providers" item-text="nombre" item-value="id" v-model="providerId"
                        label="Proveedores" required outlined prepend-inner-icon="fas fa-id-card-alt"
                        hint="Seleccione un proveedor. Este campo es opcional">
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click.native="downloadExcelEntryDetail">Descargar Excel</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import SingleCalendar from '@/components/shared/SingleCalendar.vue';
import sucursalDataService from '@/service/catalogo/sucursal/sucursalDataService';
import EntradaDetalleDataService from '@/service/entrada/EntradaDetalleDataService';
import ProductoDataService from '@/service/producto/ProductoDataService';
import { getDateLocaleNatural, getToday } from '@/utils/DateUtil';


export default {
    name: 'ExcelExportEntryDetail',
    props: {
        dialog: { type: Boolean, default: false },
        handleModal: { type: Function },
        typeEntry: { type: Array },
        providers: { type: Array },
        brands: { type: Array },
        isAdmin: { type: Boolean, default: false }
    },
    components: { SingleCalendar },
    created(){
        this.getProducts();
    },
    data(){
        return {
            startDate: '',
            endDate: '',
            typeEntryId: null,
            providerId: null,
            brandId: sucursalDataService.getCurrentSucursalId(),
            productId: null,
            showDetailByProduct: false,
            products: [],
        }
    },
    methods: {
        async getProducts() {
            this.products = await ProductoDataService.findAll().then( resp => resp.data );
        },
        downloadExcelEntryDetail(){
            const startDate = getDateLocaleNatural(this.startDate);
            const endDate = getDateLocaleNatural(this.endDate);
            this.$swal.fire({
                title: 'Quieres continuar con la descarga del reporte de entradas?',
                text: 'El tiempo de descarga puede variar dependiendo de la cantidad de registros',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    const params = { startDate, endDate, sucursalId: this.brandId, typeEntryId: this.typeEntryId, productId: this.productId, providerId: this.providerId };
                    try {
                        return await EntradaDetalleDataService.downloadEntryDetailByExcel(params);
                    } catch (error) {
                        this.$swal.showValidationMessage(`Petición fallida: ${error}`);
                    }
                },
                allowOutsideClick: () => !this.$swal.isLoading()
            }).then( result => {
                if (result.isConfirmed) {
                if( result.value.status == 204 ) {
                    this.$swal.fire({ icon: 'info', title: 'Reporte', text: 'No hay registros para descargar', showConfirmButton: false, timer: 1500 });
                } else {
                    const url = window.URL.createObjectURL(new Blob([result.value.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `Entradas ${startDate} - ${endDate}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    this.$swal.fire({ icon: 'success', title: 'Reporte', text: 'Reporte descargado', showConfirmButton: false, timer: 1500 });
                }
                }
            }).catch(err => {
                this.$swal.fire({ position: 'top-end', icon: 'error', html: 'Error al obtener los datos' + err, showConfirmButton: false, timer: 3500 });
            })
        }
    }
}
</script>